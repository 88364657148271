import {
	parseVideo,
	injectVimeo,
	injectYouTube,
	vimeoThumbnail,
	trackVimeo,
} from "./functions";

$(document).on("click", ".videoPopupTrigger", function (e) {
	e.preventDefault();

	var iframeId = $(this).attr("data-id");
	var videoLink = $(this).attr("href");
	var videoId = parseVideo(videoLink);

	if (videoLink.indexOf("vimeo") > -1) {
		injectVimeo(videoId, iframeId);
		trackVimeo(iframeId);
	}
	if (videoLink.indexOf("youtube") > -1) {
		injectYouTube(videoId, "modal_video_inject");
	}

	$(".modal_video").fadeIn();
	setTimeout(function () {
		$(".modal_video").addClass("active");
	}, 500);
});

$(document).on("click", ".resourcePopupTrigger", function () {
	e.preventDefault();

	var url = $(this).attr("href");
	var iframe = '<iframe src="' + url + '"></iframe>';

	$(".modal_resource").fadeIn();
	$(".resource").append(iframe);

	setTimeout(function () {
		$(".modal_resource").addClass("active");
	}, 500);
});

$(document).on("click", ".rc-popup-trigger", function (e) {
	e.preventDefault();

	const popup = $(this).attr("data-popup");

	$('.ch-popupModal[data-popup="' + popup + '"]').fadeIn();

	setTimeout(function () {
		$('.ch-popupModal[data-popup="' + popup + '"]').addClass("active");
	}, 500);
});

$(".ch-popupModal .closePopup").click(function () {
	const modal = $(".ch-popupModal");
	const iframe = modal.find("iframe");
	modal.removeClass("active");

	setTimeout(function () {
		modal.fadeOut();

		if (iframe.length) {
			const iframeId = iframe.attr("id");
			iframe.replaceWith('<div id="' + iframeId + '"></div>');
		}
	}, 250);
});

$(".videoThumbnail").each(function () {
	var element = $(this);
	var videoLink = $(this).attr("href");
	var videoId = parseVideo(videoLink);

	$(this).addClass(videoId);

	if (videoLink.indexOf("vimeo") > -1) {
		vimeoThumbnail(videoId, function (thumbnailUrl) {
			var newThumbnailUrl = thumbnailUrl.replace("640", "375");
			element.append('<img src="' + newThumbnailUrl + '"/>');
		});
	} else if (videoLink.indexOf("youtube") > -1) {
		var thumbnailUrl = "http://img.youtube.com/vi/" + videoId + "/0.jpg";
		$(this).append('<img src="' + thumbnailUrl + '"/>');
	}
});

$(".video-inliner").each(function () {
	var iframeId = $(this).find(".inject").attr("id");
	var video_link = $(this).attr("data-url");
	var videoId = parseVideo(video_link);

	if (video_link.indexOf("vimeo") > -1) {
		injectVimeo(videoId, iframeId, $(this));
		trackVimeo(iframeId);
	} else if (video_link.indexOf("youtube") > -1) {
		injectYouTube(videoId, iframeId);
	}
});

// take care of this
$(".blogElement-videotimestamps").each(function (video_number) {
	var iframeID = $(this).find(".inject").attr("id");
	var playerVar = "player" + iframeID;

	$(this)
		.find(".responsive-video")
		.each(function () {
			var videoLink = $(this).attr("data-video-url");
			var videoId = parseVideo(videoLink);

			if (videoLink.indexOf("vimeo") > -1) {
				injectVimeo(videoId, iframeID, $(this));
				trackVimeo(iframeID);
			} else if (videoLink.indexOf("youtube") > -1) {
				// cant use injectYoutube function for now, get go get back to this
				window.YT.ready(function () {
					window[playerVar] = new YT.Player(iframeID, {
						videoId: videoId,
					});
					var ytAnalytics =
						new window.analytics.plugins.YouTubeAnalytics(
							playerVar,
							"AIzaSyCiHRPdsawGUO7LuR_FYP5By46Fw-3hqfo"
						);
					ytAnalytics.initialize();
				});
			}
		});
});

$(".blogElement-videotimestamps .timestamps button").click(function () {
	var seek = $(this).attr("data-seek");
	var id = $(this).attr("data-vid");
	window["player" + id].seekTo(seek);
	return false;
});
