export function parseVideo(url) {
	url.match(
		/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com)|youtube\.com\/shorts)\/(video\/|embed\/|watch\?v=|v\/|shorts\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
	);

	if (
		RegExp.$3.indexOf("youtu") > -1 ||
		RegExp.$3.indexOf("youtube.com/shorts") > -1
	) {
		var type = "youtube";
	} else if (RegExp.$3.indexOf("vimeo") > -1) {
		var type = "vimeo";
	}

	return RegExp.$6;
}

export function vimeoThumbnail(id, callback) {
	$.getJSON(
		"https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + id,
		{ format: "json" },
		function (data) {
			var thumbnail_src = data.thumbnail_url;
			callback(thumbnail_src);
		}
	);
}

export function injectVimeo(videoId, iframeId, injectHere) {
	var playerVar = "player" + iframeId;
	var iframeMarkup =
		'<iframe id="' +
		iframeId +
		'" src="https://player.vimeo.com/video/' +
		videoId +
		'?color=ffffff" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

	if (injectHere) {
		injectHere.html(iframeMarkup);
	} else {
		$("#modal_video_inject").replaceWith(iframeMarkup);
	}
}

export function trackVimeo(iframeId) {
	setTimeout(function () {
		analytics.ready(function () {
			var iframe = $("iframe");
			var player = new Vimeo.Player(iframe);
			var VimeoAnalytics = window.analytics.plugins.VimeoAnalytics;
			var vimeoAnalytics = new VimeoAnalytics(
				player,
				"00f25b79a32b8358db272998b2cb60f4"
			);
			vimeoAnalytics.initialize();
		});
	}, 1500);
}

export function injectYouTube(videoId, iframeId) {
	setTimeout(function () {
		window.YT.ready(function () {
			var thingy = new YT.Player(iframeId, {
				videoId: videoId,
				playerVars: {
					autoplay: 1,
				},
				events: {
					onReady: function (event) {
						event.target.a.id = iframeId;
					},
				},
			});
			analytics.ready(function () {
				var ytAnalytics = new window.analytics.plugins.YouTubeAnalytics(
					thingy,
					"AIzaSyCiHRPdsawGUO7LuR_FYP5By46Fw-3hqfo"
				);
				ytAnalytics.initialize();
			});
		});
	}, 1500);
}
